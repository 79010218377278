/**
 * import styles into webpack bundler
 */
import 'scss/front/dizu.scss'

/**
 * import icons into webpack bundler
 */
/* brands */

/* favorites */

/* flags */

/* general */


/**
 * import images into webpack bundler
 */
/* backgrounds */
import 'images/backgrounds/img_background_breadcrumb_2.jpg'
import 'images/backgrounds/img_background_call_to_action.jpg'
import 'images/backgrounds/img_background_curvy_road.jpg'
import 'images/backgrounds/img_background_hero.png'
import 'images/backgrounds/img_background_testimonials.jpg'
/* content */
import 'images/content/img_content_error_404.png'
import 'images/content/img_content_first_aid_defibrillator.jpg'
import 'images/content/img_content_first_aid_gallery.jpg'
import 'images/content/img_content_hero_1.png'
import 'images/content/img_content_hero_2.png'

/**
 * import illustrations into webpack bundler
 */


/**
 * import placeholders into webpack bundler (visit: https://placehold.co/)
 */
import 'images/placeholders/img_placeholder_300x250.svg'
import 'images/placeholders/img_placeholder_540x380.svg'
import 'images/placeholders/img_placeholder_600x600.svg'
import 'images/placeholders/img_placeholder_700x700.svg'
import 'images/placeholders/img_placeholder_1140x600.svg'
import 'images/placeholders/img_placeholder_1920x650.svg'
import 'images/placeholders/img_placeholder_1920x700.svg'
import 'images/placeholders/img_placeholder_1920x881.svg'

// =========================================================
// Dizu - vx.x.x
// =========================================================

// Product Page: https://themeforest.net/item/dizu-driving-school-html-template/36585205
// Copyright 2023 Creative Tim (https://www.creative-tim.com)
// Licensed under MIT (https://github.com/creativetimofficial/soft-ui-dashboard/blob/main/LICENSE)

// Coded by https://themeforest.net/user/hibootstrap

// =========================================================

// The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

(function ($) {
    "use strict";

    //Preloader
    // $(window).on('load', function (event) {
    //     $('.js-preloader').delay(500).fadeOut(500);
    // });

    window.addEventListener('load', function () {
        $('.js-preloader').fadeOut(500);
    })

    //Open Mobile Sidebar
    $('.mobile-sidebar').on('click', function() {
        $('.header-top').toggleClass('open');
    });
    $('.close-sidebar').on('click', function() {
        $('.header-top').removeClass('open');
    });

    //Open Search Box
    $('.searchbtn').on('click', function() {
        $('.search-area').toggleClass('open');
    });
    $('.close-searchbox').on('click', function() {
        $('.search-area').removeClass('open');
    });

    //Counter
    $(".odometer").appear(function (e) {
        var odo = $(".odometer");
        odo.each(function () {
            var countNumber = $(this).attr("data-count");
            $(this).html(countNumber);
        });
    });

    // Language Dropdown
    $(".language-option").each(function () {
        var each = $(this)
        each.find(".lang-name").html(each.find(".language-dropdown-menu a:nth-child(1)").text());
        var allOptions = $(".language-dropdown-menu").children('a');
        each.find(".language-dropdown-menu").on("click", "a", function () {
            allOptions.removeClass('selected');
            $(this).addClass('selected');
            $(this).closest(".language-option").find(".lang-name").html($(this).text());
        });
    })

    //Tweenmax js
    $('.hero-wrap.style3').mousemove(function (e) {
        var wx = $(window).width();
        var wy = $(window).height();
        var x = e.pageX - this.offsetLeft;
        var y = e.pageY - this.offsetTop;
        var newx = x - wx / 2;
        var newy = y - wy / 2;
        $('.hero-content').each(function () {
            var speed = $(this).attr('data-speed');
            if ($(this).attr('data-revert')) speed *= -.4;
            TweenMax.to($(this), 1, { x: (1 - newx * speed), y: (1 - newy * speed) });
        });
    });

    //Hero  Slider
    $(".hero-slider-one").owlCarousel({
        nav: true,
        dots: false,
        loop: true,
        margin: 0,
        items: 1,
        navText: ['<i class="flaticon-left-arrow"></i>', '<i class="flaticon-right-arrow"></i>'],
        thumbs: false,
        smartSpeed: 1300,
        autoplay: false,
        autoplayTimeout: 4000,
        autoplayHoverPause: false,
        responsiveClass: true,
        autoHeight: true,
    });
    $(".hero-img-slider").owlCarousel({
        nav: true,
        dots: false,
        loop: true,
        margin: 15,
        items: 1.15,
        rtl:true,
        navText: ['<i class="flaticon-left-arrow"></i>', '<i class="flaticon-right-arrow"></i>'],
        thumbs: false,
        smartSpeed: 1300,
        autoplay: false,
        autoplayTimeout: 4000,
        autoplayHoverPause: false,
        responsiveClass: true,
        autoHeight: true,
    });

    //Testimonial Slider
    $(".testimonial-slider-one").owlCarousel({
        nav: false,
        dots: true,
        loop: true,
        margin: 25,
        items: 1,
        thumbs: false,
        smartSpeed: 1300,
        autoplay: false,
        autoplayTimeout: 4000,
        autoplayHoverPause: false,
        responsiveClass: true,
        autoHeight: true,
    });
    $(".testimonial-slider-two").owlCarousel({
        nav: false,
        dots: true,
        loop: true,
        margin: 25,
        items: 1,
        thumbs: false,
        smartSpeed: 1300,
        autoplay: false,
        autoplayTimeout: 4000,
        autoplayHoverPause: false,
        responsiveClass: true,
        autoHeight: true,
        responsive: {
            0: {
                items: 1,
                margin:0,
            },
            768: {
                margin:0,
                items: 1,
            },
            992: {
                items: 2,
            }
        }
    });
    $(".testimonial-slider-three").owlCarousel({
        nav: true,
        dots: false,
        loop: true,
        margin: 25,
        items: 1,
        thumbs: false,
        navText: ['<i class="flaticon-left-arrow"></i>', '<i class="flaticon-right-arrow"></i>'],
        smartSpeed: 1300,
        autoplay: false,
        autoplayTimeout: 4000,
        autoplayHoverPause: false,
        responsiveClass: true,
        autoHeight: true,
        responsive: {
            0: {
                items: 1,
            },
            768: {
                items: 2,
            },
            1200: {
                items: 2,
            }
        }
    });

    //Popular Post Slider
    $(".popular-post-slider").owlCarousel({
        nav: false,
        dots: true,
        loop: true,
        margin: 20,
        items: 1,
        thumbs: false,
        smartSpeed: 1300,
        autoplay: false,
        autoplayTimeout: 4000,
        autoplayHoverPause: false,
        responsiveClass: true,
        autoHeight: true,
    });

    //Team Slider
    $(".team-slider-one").owlCarousel({
        nav: true,
        dots: false,
        navText: ['<i class="flaticon-left-arrow"></i>', '<i class="flaticon-right-arrow"></i>'],
        loop: true,
        margin: 25,
        items: 1,
        thumbs: false,
        smartSpeed: 1300,
        autoplay: false,
        autoplayTimeout: 4000,
        autoplayHoverPause: false,
        responsiveClass: true,
        autoHeight: true,
        responsive: {
            0: {
                items: 1,
            },
            768: {
                items: 2,
            },
            1200: {
                items: 3,
            }
        }
    });

    //Course Slider
    $(".course-slider-one").owlCarousel({
        nav: true,
        dots: false,
        navText: ['<i class="flaticon-left-arrow"></i>', '<i class="flaticon-right-arrow"></i>'],
        loop: true,
        margin: 25,
        items: 1,
        thumbs: false,
        smartSpeed: 1300,
        autoplay: false,
        autoplayTimeout: 4000,
        autoplayHoverPause: false,
        responsiveClass: true,
        autoHeight: true,
        responsive: {
            0: {
                items: 1,
            },
            768: {
                items: 2,
            },
            1200: {
                items: 3,
            }
        }
    });

    //Course Slider
    $(".partner-slider-one").owlCarousel({
        nav: true,
        dots: false,
        navText: ['<i class="flaticon-left-arrow"></i>', '<i class="flaticon-right-arrow"></i>'],
        loop: true,
        //margin: 25,
        items: 1,
        thumbs: false,
        smartSpeed: 1300,
        autoplay: false,
        autoplayTimeout: 4000,
        autoplayHoverPause: false,
        responsiveClass: true,
        autoHeight: true,
        responsive: {
            0: {
                items: 1,
            },
            768: {
                items: 3,
            },
            1200: {
                items: 4,
            }
        }
    });

    //Vehicle Slider
    $(".vehicle-slider-one").owlCarousel({
        nav: true,
        dots: false,
        navText: ['<i class="flaticon-left-arrow"></i>', '<i class="flaticon-right-arrow"></i>'],
        loop: true,
        margin: 25,
        items: 1,
        thumbs: false,
        smartSpeed: 1300,
        autoplay: false,
        autoplayTimeout: 4000,
        autoplayHoverPause: false,
        responsiveClass: true,
        autoHeight: true,
        responsive: {
            0: {
                items: 1,
            },
            768: {
                items: 2,
            },
            1200: {
                items: 3,
            }
        }
    });

    //Blog Slider
    $(".blog-slider-one").owlCarousel({
        nav: true,
        dots: false,
        loop: true,
        margin: 20,
        items: 1,
        navText: ['<i class="flaticon-left-arrow"></i>', '<i class="flaticon-right-arrow"></i>'],
        thumbs: false,
        smartSpeed: 1300,
        autoplay: false,
        autoplayTimeout: 4000,
        autoplayHoverPause: false,
        responsiveClass: true,
        autoHeight: true,
        responsive: {
            0: {
                items: 1,
            },
            768: {
                items: 2,
            },
            1200: {
                items: 3,
            }
        }
    });

    //sticky Header
    var wind = $(window);
    var sticky = $('.header-wrap');
    wind.on('scroll', function () {
        var scroll = wind.scrollTop();
        if (scroll < 100) {
            sticky.removeClass('sticky');
        } else {
            sticky.addClass('sticky');
        }
    });

    // Responsive mmenu
    $(window).on('resize', function() {
        if($(window).width() <= 1199) {
            $('.collapse.navbar-collapse').removeClass('collapse');
        }else{
            $('.navbar-collapse').addClass('collapse');
        }
    });
    $('.mobile-menu a').on('click', function() {
        $('.main-menu-wrap').addClass('open');
        $('.collapse.navbar-collapse').removeClass('collapse');
    });

    $('.mobile_menu a').on('click', function () {
        $(this).parent().toggleClass('open');
        $('.main-menu-wrap').toggleClass('open');
    });

    $('.menu-close').on('click', function () {
        $('.main-menu-wrap').removeClass('open')
    });
    $('.mobile-top-bar').on('click', function () {
        $('.header-top').addClass('open')
    });
    $('.close-header-top button').on('click', function () {
        $('.header-top').removeClass('open')
    });
    var $offcanvasNav = $('.navbar-nav'),
        $offcanvasNavSubMenu = $offcanvasNav.find('.dropdown-menu');
    $offcanvasNavSubMenu.parent().prepend('<span class="menu-expand"><i class="ri-arrow-down-s-line"></i></span>');
    $offcanvasNavSubMenu.slideUp();
    $offcanvasNav.on('click', 'li a, li .menu-expand', function (e) {
        var $this = $(this);
        if (($this.attr('href') === '#' || $this.hasClass('menu-expand'))) {
            e.preventDefault();
            if ($this.siblings('ul:visible').length) {
                $this.siblings('ul').slideUp('slow');
            } else {
                $this.closest('li').siblings('li').find('ul:visible').slideUp('slow');
                $this.siblings('ul').slideDown('slow');
            }
        }
        if ($this.is('a') || $this.is('span') || $this.attr('class').match(/\b(menu-expand)\b/)) {
            $this.parent().toggleClass('menu-open');
        } else if ($this.is('li') && $this.attr('class').match(/\b('dropdown-menu')\b/)) {
            $this.toggleClass('menu-open');
        }
    });

    // Scroll animation
    AOS.init();

})(jQuery);